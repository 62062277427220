import React from 'react';
import PropTypes from 'prop-types';
import NewsGoodToKnow from '../warto-wiedziec';

const NewsPage = ({ category }) => {
  return <NewsGoodToKnow category={category} />;
};

NewsPage.propTypes = {
  slug: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};

export default NewsPage;
